<template>
    <div id="transactions-list">
      <b-alert 
        variant="warning"
        :show="dismissCountDown"
        dismissible
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged">
          <h4 class="alert-heading">
            Preencha as datas corretamente!
          </h4>
        </b-alert>
        <div class="row">
          <div class="col-lg-6">
            <b-card title="Selecione o intervalo de datas">
                <div class="d-md-flex">
                    <b-form-group
                      label="Data inicial"
                      label-for="initialDate"
                    >
                      <b-form-datepicker size="lg" locale="pt-BR" id="initialDate" v-model="initialDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2"></b-form-datepicker>
                    </b-form-group>
                    <b-form-group
                      label="Data final"
                      label-for="finalDate"
                      class="ml-md-5"
                    >
                      <b-form-datepicker size="lg" locale="pt-BR" id="finalDate" v-model="finalDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2"></b-form-datepicker>
                    </b-form-group>
                    <button class="btn-relief-primary research-button ml-md-3" @click="searchTransactions()">Pesquisar</button>
                </div>
            </b-card>
          </div>
          <div class="col-lg-6" v-if="resultObject">
            <b-card title="Valores consolidados" class="consolidated">
                <div class="d-md-flex">
                    <div class="col">
                      <h5>Total em depositos</h5>
                      <p class="cons-value text-success">R$ {{resultObject.depositsSum}},00</p>
                    </div>
                    <div class="col">
                      <h5>Total em saques</h5>
                      <p class="cons-value text-danger">R$ {{resultObject.withdrawsSum}},00</p>
                    </div>
                    <div class="col">
                      <h5>Resultado no período</h5>
                      <p class="cons-value text-warning">R$ {{resultObject.depositsSum - resultObject.withdrawsSum}},00</p>
                    </div>
                </div>
            </b-card>
          </div>
        </div>
        <div v-if="this.resultObject.usersDeposits">
          <h5>Depósitos de usuários</h5>
          <b-table stripe :items="this.resultObject.usersDeposits" :fields="depositFields"></b-table>
        </div>
        <div v-if="this.resultObject.usersWithdraws">
          <h5>Saques de usuários</h5>
          <b-table stripe :items="this.resultObject.usersWithdraws" :fields="depositFields"></b-table>
        </div>
        
    </div>
</template>

<script>
import {BCard,BRow, BCol, BTable,BFormDatepicker, BFormGroup, BAlert} from 'bootstrap-vue'
export default{
  data () {
    return {
      depositFields : ['description', 'email', 'user_name', 'value', 'status', 'created_at'],
      transactions:false,
      initialDate : "",
      finalDate : "",
      dismissCountDown:0,
      dismissSecs:2,
      resultObject: false,
    }
  },
  computed: {},
  methods: {
    getDeposits(){
      this.$httpPlatform.get('https://api.bingolar.tv/api/platform-operations/getLastDeposits')
      .then(res => {
          this.transactions = res.data;
          console.log(res.data)
      })
    },
    searchTransactions(){
      // var date1 = new Date(this.initialDate);
      // var date2 = new Date(this.finalDate);
      // date1.setDate(date1.getDate()+1);
      // date2.setDate(date2.getDate()+1);
      // var diffDays = date2.getDate() - date1.getDate(); 
      if((this.initialDate == "") || (this.finalDate == "")){
        this.showAlert()
      }else{
        this.goSearch();
      }
    },
    goSearch(){
      this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/getMasterReport',{
        iniDate:this.initialDate,
        finDate:this.finalDate,
        inviteCode:JSON.parse(localStorage.getItem('userData')).inviteCode,
      })
      .then(res => {
        console.log(res.data)
        this.resultObject = res.data;
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
    
  },
  components: {
    BCard,
    BTable,
    BRow, BCol,BFormDatepicker,BFormGroup,BAlert
  },
  created() {
    this.getDeposits();
  }
}
</script>

<style>
  .research-button{
    max-height: 50px;
    padding:  0 10px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
    text-transform: uppercase;
    border-radius: 5px;
  }
  .consolidated{
    min-height: 190px;
  }
  .cons-value{
    font-size:25px;
    margin-top: 10px;
    font-weight: bold;
  }
  #initialDate__dialog_{
    left:100px;
    position:relative;
    z-index: 30;
  }
  #finalDate__dialog_{
    z-index:30;
  }
</style>
